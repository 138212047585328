<template>
  <v-tooltip right:disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <span
        class="d-flex"
        :class="['order-status', 'order-status-' + order.orderStatusId]"
      >
        {{ orderStatus }}
        <v-icon v-bind="attrs" v-on="on" v-if="!disabled">$info</v-icon>
      </span>
    </template>
    <span>{{ orderStatusTooltip }}</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    order: { type: Object, required: true },
    disabled: { type: Boolean, default: true }
  },
  computed: {
    orderStatus() {
      return global.vm.$t(
        `order.orderStatusDescr[${this.order.orderStatusId}]`
      );
    },
    orderStatusTooltip() {
      return global.vm.$t(
        `order.orderStatusTooltip[${this.order.orderStatusId}]`
      );
    }
  }
};
</script>
